/* ===========================
Root Variables
=========================== */
:root {
    --primary-color: #FF5A27;
    --primary-gray: #332D2F;
    --text-base:#332D2F;
    --gray-400: #f1f1f1;
    --gray-500: #FAF7FF;
    --gray-600: #EFEBF5;
    --yello-500: #FAF5EB;
    --yello-600: #F8EFDE;
    --white: #fff;
    --black: #000;
    --font-small: 14px;
    --font-base: 16px;
    --body-fontFamily: "Archivo";
}
body {
    font-family: var(--body-fontFamily);
    color: var(--text-base);
    font-size: var(--font-base);
    line-height: 1.5;
    margin: 0;
    padding: 0;
}
a {
    color: var(--primary-color);
    text-decoration: none;
}
img {
    max-width: 100%;
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
h1, h2, h3, h3, h4, h5, h6, p{
    color: var(--text-base);
}
h3.MuiTypography-h3 {
    font-size: 3.2rem;
}
.link {
    color: var(--primary-color);
    text-decoration: none;
}
.link:hover {
    text-decoration: underline;
}

body .MuiTypography-root, body .MuiChip-root, 
body table tr th, body table tr td, body .MuiTableCell-root {
    font-family: var(--body-fontFamily);
}

/* Background color classes */
.bg-primary {
    background-color: var(--primary-color);
}
.bg-primary-gray {
    background-color: var(--primary-gray);
}
.bg-white {
    background-color: var(--white);
}
.bg-gray-400 {
    background-color: var(--gray-400);
}

/* Text color classes */
.text-primary {
    color: var(--primary-color);
}
.text-base {
    color: var(--text-base);
}
.text-white {
    color: var(--white);
}
.font-small {
    font-size: var(--font-small);
}
/* Custom button design */
.theme-btn.MuiButton-root, 
.theme-btn {
    font-family: var(--body-fontFamily);
    font-size: var(--font-small);
    color: var(--white);
    height: 48px;
    line-height: 48px;
    padding: 0 28px;
    border-radius: 60px;
    text-transform: capitalize;
    border: solid 1px var(--primary-gray);
    background-color: var(--primary-gray);
    font-weight: 400;
    white-space: nowrap;
}
.theme-btn.lite-btn.MuiButton-root {
    color: var(--primary-gray);
    background-color: var(--yello-500);
    border: solid 1px var(--yello-500);
    font-weight: 500;
}
.theme-btn.outline {
    font-weight: 600;
    color: var(--primary-gray);
    background-color: transparent;
}
.theme-btn.MuiButton-root:not([disabled]):hover, .theme-btn.active {
    color: var(--white);
    background-color: var(--primary-color);
    border: solid 1px var(--primary-color);
}
.theme-btn.MuiButton-root.Mui-disabled{
    opacity: 0.8;
    color: var(--white);
}

/* Google Button */
.googleButton .theme-btn {
    font-family: var(--body-fontFamily) !important;
    font-size: var(--font-small) !important;
    color: var(--white) !important;
    height: 48px !important;
    padding: 0 28px !important;
    border-radius: 60px !important;
    text-transform: capitalize !important;
    background-color: var(--primary-gray) !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.googleButton .theme-btn > div {
    display: none !important;
}
.googleButton .theme-btn > span {
    color: var(--white);
    padding: 0 !important;
    font-weight: 400 !important;
}

/* Lite button */
.googleButton.lite-btn .theme-btn,
.header-btn .theme-btn { 
    color: var(--primary-gray);
    background-color: var(--yello-500) !important;
}
.googleButton.lite-btn .theme-btn > span, 
.header-btn .theme-btn > span {
    color: var(--primary-gray);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    font-weight: 500 !important;
}
.googleButton .theme-btn:not([disabled]):hover, 
.googleButton.lite-btn .theme-btn:not([disabled]):hover, 
.header-btn .theme-btn:not([disabled]):hover{ 
    color: var(--white);
    background-color: var(--primary-color) !important;
}
.googleButton .theme-btn:not([disabled]):hover > span, 
.googleButton.lite-btn .theme-btn:not([disabled]):hover > span,
.header-btn .theme-btn:not([disabled]):hover > span {
    color: var(--white);
}


/*  MUI Container space CSS   */
.MuiContainer-root.t-space,
.MuiContainer-root.space {
    padding-top: 64px;
}
.MuiContainer-root.b-space, 
.MuiContainer-root.space {
    padding-bottom: 64px;
}
.muiAlert-root-wrap {
    display: inline-block;
}

ul.star-list > li {
    background-image: url('../images/list-star-icon.png');
    background-repeat: no-repeat;
    background-position: 0 12px;
    background-size: 20px;
    padding-right: 0;
    padding-left: 26px;
}
.MuiListItemText-root.font-small > span {
    font-size: 14px;
}
/*========================================
Main Header
=========================================*/
.main-header .main-logo {
    line-height: 0;
}
.main-header.MuiAppBar-root {
    background-color: var(--primary-gray);
    box-shadow: none;
}
.main-menu .menu-item > a {
    font-size: var(--font-base);
    color: var(--white);
    font-weight: 300;
    margin: 0 15px;
    text-decoration: none;
    -webkit-transition: all 0.23s ease;
    transition: all 0.23s ease;
}
.main-menu .menu-item > a:hover {
    color: var(--primary-color);
}
.menuToggle button, .menuToggle button:hover {
    color: var(--white);
    border-color: var(--white);
}

/* Mobile Menu */
.sidebar-logo {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    padding-top: 3px;
    padding-bottom: 12px;
}

.side-menu-item {
    color: #fff;
    font-weight: 200;
}

/*========================================
Page title section
=========================================*/
.pageHead-section {
    padding: 40px 0;
    background-color: var(--gray-400);
}
.pageHead-section h1.pageTitle {
    font-size: 56px;
    font-weight: 600;
    min-height: 200px;
    display: flex;
    align-items: center;
}
.MuiPaper-root.MuiAlert-root {
    display: inline-flex;
}
/*========================================
Home Banner
=========================================*/
.banner-wrap {
    background-image: url('../images/home_banner_bg.png');
    background-color: #D1CCDB;
    background-size: 90%;
    background-repeat: repeat;
}
.banner-wrap .offer-img {
    width: 100px;
    position: absolute;
    right: -150px;
    bottom: -75px;
}
/* .banner-wrap .circleLine-shap {
    width: 46px;
    position: absolute;
    left: -25px;
    top: -26px;
} */
.bannerTestShap {
    position: absolute;
    right: -36px;
    top: -48px;
    width: 120px;
}
.MuiIconButton-root.modal-close-btn {
    position: absolute;
    right: 8px;
    top: 8px;
    color: rgba(0, 0, 0, 0.38);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.MuiIconButton-root.modal-close-btn:hover {
    background-color: var(--primary-color);
    color: #fff;
}
/*========================================
Main Footer
=========================================*/
.footer-menu a {
    font-size: var(--font-small);
    color: var(--white);
    margin-right: 20px;
    font-weight: 300;
    text-decoration: underline;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    white-space: nowrap;
}
.footer-menu a:last-child {
    margin-right: 0;
}
.footer-menu a:hover {
    color: var(--primary-color);
}

.footer-menu .ftr-googleButton {
    display: inline-block;
    margin-right: 20px;
}
.footer-menu .ftr-googleButton .theme-btn {
    padding: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
    height: auto !important;
    line-height: initial !important;
    text-decoration: underline !important;
    font-size: var(--font-small) !important;
    font-weight: 300 !important;
    border: none;
    color: #fff;
}
.footer-menu .ftr-googleButton .theme-btn:not([disabled]):hover {
    color: var(--primary-color);
    border: none;
}
/*========================================
End
=========================================*/