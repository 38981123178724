/*========================================
Contact page
=========================================*/
.contact-location .adress-details .MuiListItemText-primary {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    margin-bottom: 8px;
    font-weight: 500;
}
.contact-location .adress-details .MuiListItemText-secondary {
    color: var(--text-primary);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 0;
}
/*========================================
GA4 check account
=========================================*/
.gaAccount-list {
    column-count: 2;
    column-gap: 200px;
}
/*========================================
Home page Testimonials Slider section
=========================================*/
.testimonial-card {
    background: #FAF5EB;
    border-top: solid 34px #F8EFDE;
    padding: 26px 26px 36px;
    -webkit-transform: rotate(-3.5deg);
    transform: rotate(-3.5deg);
    max-width: 470px;
    margin: 0 auto;
    height: 100%;
}
.testimonial-card.middle {
    background: var(--primary-gray);
    border-top: solid 34px #2B2626;
    color: #fff;
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
}
.testimonial-card.right {
    background: var(--gray-500);
    border-top: solid 34px var(--gray-600);
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
}
.testimonial-card .testimonial-head {
    display: flex;
    align-items: center;
    position: relative;
}
.testimonial-head .client-img {
    background: var(--primary-color);
    width: 84px;
    height: 84px;
}
.head-shap {
    text-align: center;
    margin-top: 7px;
}
.head-shap img {
    width: 60px;
}
.testimonial-head .head-arrowshap {
    position: absolute;
    right: 10px;
    top: 50px;
}
.testimonial-head .head-arrowshap2 {
    position: absolute;
    right: 0;
    top: 85px;
    max-height: 72px;
}
.testimonial-card .client-details {
    padding-left: 18px;
}
.testimonial-card .client-details .title {
    color: var(--black);
    font-size: 20px;
    font-weight: 600;
    display: inline-block;
    position: relative;
}
.testimonial-card .client-details .title:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 74px;
    height: 2px;
    background-color: var(--primary-color);
}
.testimonial-body {
    color: var(--black);
    font-size: var(--font-small);
    margin-top: 26px;
    padding: 0 14px;
    font-weight: 500;
}
.testimonial-card .client-details .subtitle {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
    line-height: 1.2;
    padding-right: 50px;
}
.testimonial-card.middle .title, 
.testimonial-card.middle .subtitle {
    color: #fff;
    font-weight: 300;
}
.testimonial-card.middle .subtitle {
    padding-right: 24px;
}
.testimonial-card.middle .testimonial-body {
    font-weight: 300;
    color: var(--white);
}
.testimonial-slider .react-multi-carousel-dot button {
    width: 8px;
    height: 8px;
    border-width: 1px;
}
.testimonial-slider .react-multi-carousel-list {
    padding-bottom: 40px;
}
/*========================================
Custom loader design
=========================================*/
.ctm-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*========================================
Home client logo slider
=========================================*/
.clientLogo-slider li.react-multi-carousel-item .client-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
/*========================================
Home powered by section
=========================================*/
.powered-shap {
    transform: rotate(-34deg);
    width: 58px;
    margin-left: 46px;
    margin-bottom: -18px;
}
.selectView-popup:focus-visible {
    outline: none;
}

/*========================================
Report Filter page
=========================================*/
.filterResult-btn-wrap .theme-btn {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
    height: 64px;
    border-radius: 0;
}
.report-testTypes-wrap .theme-btn {
    margin-right: 8px;
    margin-bottom: 8px;
    height: 34px;
    font-weight: 400;
}
body .report-testTypes-wrap .theme-btn:not([disabled]):hover, 
body .filterResult-btn-wrap .theme-btn:not([disabled]):hover {
    border: solid 1px var(--primary-gray);
    background-color: var(--primary-gray);
    color: var(--white);
}
.analyticsReport-tab .MuiAccordion-root {
    border-color: rgba(0,0,0,0.20) !important;
}
.analyticsReport-tab .MuiAccordion-root.Mui-expanded {
    border-color: var(--primary-gray) !important;
}
/*========================================
Select a view popup
=========================================*/
.selectView-table table {
    border-top: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.selectView-table table tr th {
    font-size: 18px;
}
.selectView-table table tr td {
    border-bottom: none;
    padding: 1px 0;
}
.selectView-table table tbody tr:first-child td {
    padding-top: 10px;
}
.selectView-table .selectView {
    font-size: 13px;
    text-decoration: none;
    font-weight: 600;
    color: var(--text-base);
    padding: 12px 18px;
    background: transparent;
    display: inline-block;
    min-width: 150px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.selectView-table .selectView:hover, 
.selectView-table .selectView.active {
    background: #D9D9D9;
}
body .selectView-popup {
    width: 90%;
    max-width: 100%;
    max-height: calc(100vh - 30px);
    overflow: auto;
}
/*========================================
End
=========================================*/
