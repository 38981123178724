/*========================================
Responsive CSS
=========================================*/
@media (max-width: 1535.99px) {
    .banner-wrap .offer-img {
        width: 80px;
        right: 0;
        bottom: -84px;
    }
}
@media (max-width: 1199.99px) {
    .testimonial-head .head-arrowshap2, .testimonial-head .head-arrowshap {
        display: none;
    }
    .testimonial-head .client-img {
        width: 60px;
        height: 60px;
    }
    .testimonial-card .testimonial-head {
        padding-bottom: 20px;
    }
    .testimonial-card .client-details .title {
        font-size: 16px;
    }
    .testimonial-card .client-details .subtitle {
        font-size: 13px;
        padding-right: 0;
    }
    .bannerTestShap {
        display: none;
    }
    .head-shap img {
        width: 44px;
    }
}
@media (max-width: 899.99px) {
    .banner-wrap .offer-img {
        width: 60px;
        top: -64px;
        right: 0;
        left: auto;
        bottom: auto;
    }
    .theme-btn.MuiButton-root, .theme-btn {
        padding: 0 15px;
    }
    .testimonial-card, .testimonial-card.middle, .testimonial-card.right {
        transform: inherit;
    }
    h3.MuiTypography-h3 {
        font-size: 2.5rem;
    }
    .pageHead-section h1.pageTitle {
        font-size: 40px;
        min-height: auto;
    }
    .contact-location .adress-details .MuiListItemText-primary {
        font-weight: 400;
        font-size: 1.3rem;
    }
}
@media (max-width: 599.99px) {
    .MuiContainer-root.b-space, .MuiContainer-root.space {
        padding-bottom: 44px;
    }
    .MuiContainer-root.t-space, .MuiContainer-root.space {
        padding-top: 44px;
    }
    .filterResult-btn-wrap .theme-btn {
        height: 54px;
    }
    .ar-status-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;
    }
    body .selectView-popup {
        padding: 22px;
    }
    .MuiFormControl-root .MuiSelect-select, 
    ul.MuiMenu-list li.MuiMenuItem-root {
        font-size: 14px;
    }
    .testimonial-body {
        margin-top: 6px;
    }
    .clientLogo-slider li.react-multi-carousel-item .client-logo {
        padding: 0 20px;
    }
    h3.MuiTypography-h3 {
        font-size: 2rem;
    }
    .pageHead-section h1.pageTitle {
        font-size: 32px;
        min-height: auto;
    }
    .contact-location .adress-details .MuiListItemText-primary {
        font-weight: 400;
        font-size: 1.2rem;
        margin-bottom: 4px;
    }
    .powered-shap {
        display: none;
    }
}
@media (max-width: 499.99px) {
    .footer-menu a {
        display: block;
        margin-right: 0;
        margin-bottom: 5px;
    }
    .footer-menu .ftr-googleButton {
        margin-right: 0;
        margin-bottom: 5px;
    }
    .MuiIconButton-root.modal-close-btn {
        right: 6px;
        top: 6px;
        padding: 4px;
    }
}
/*========================================
End
=========================================*/
